import {mapMutations, mapActions, mapGetters} from "vuex";

export default {
    name: "partners",
    components: {},
    data() {
        return {
            professional: '',
            disabled: 0,

        };
    },
    computed: {
        ...mapGetters({
            isProfessional: `auth/isProfessional`,
            user: `profile/user`,
            getPromocode: 'user/generatedPromo',
            recommendations: 'auth/recommendations'
        }),

    },
    created() {
        this.professional = localStorage.getItem('professional')
        this.getRecommendations()
    },
    methods: {
        ...mapMutations({
            userFakeAuth: 'header/CHANGE_FAKE_USER_AUTH',
        }),
        ...mapActions({
            logout: `auth/LOGOUT`,
            getTest: 'user/GET_TEST',
            getRecommendations: 'auth/GET_RECOMMENDATIONS',

        }),

        getTesting() {
            this.getTest()
        },
        copyCode() {

            let copyTextarea = document.getElementById('code');
            copyTextarea.focus();
            copyTextarea.select();

            try {
                let successful = document.execCommand('copy');
            } catch (err) {

            }
            this.$toasted.success(this.$t('codeCopySuccess'))
        }
    }

};
